<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <div class="main_navbar_wrapper">
        <div class="part_one_of_navbar">
          <!-- Navbar Minimize Button  -->
          <div class="navbar-minimize">
            <button
              class="btn btn-outline btn-fill btn-round btn-icon"
              @click="minimizeSidebar"
            >
              <i
                :class="
                  $sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-navicon'
                "
              ></i>
            </button>
          </div>
          <a class="navbar-brand">{{ $t($route.name) }}</a>
        </div>
        <div>
          <el-dropdown>
            <span class="el-dropdown-link">
              Languages<i class="el-icon-arrow-down"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                ><button class="bg-transparent border-0" @click="Arabic()">
                  عربى
                </button></el-dropdown-item
              >
              <el-dropdown-item
                ><button class="bg-transparent border-0" @click="English()">
                  English
                </button></el-dropdown-item
              >
              <el-dropdown-item
                ><button class="bg-transparent border-0" @click="German()">
                  German
                </button></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },

    Arabic() {
      this.$i18n.locale = "ar";
    },
    English() {
      this.$i18n.locale = "en";
    },
    German() {
      this.$i18n.locale = "gr";
    },
    french() {
      this.$i18n.locale = "fr";
    },
  },
};
</script>

<style scoped>
i:hover {
  color: #23ccef;
}
.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}
</style>

<style lang="scss" scoped>
.main_navbar_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .part_one_of_navbar {
    display: flex;
    gap: 10px;
  }
}
</style>
