var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'd-none': _vm.checkRoute || _vm.checkRouteTwo }},[_c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[(_vm.hasPermission('access_dashboard'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('main_page'))),
            icon: 'nc-icon nc-chart-pie-35',
            path: '/overview',
          }}}):_vm._e(),(_vm.auth())?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (_vm.auth().name)),
            icon: 'fa fa-user',
            path: '/profile',
          }}}):_vm._e(),(_vm.hasPermission('access_users'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('admin_dashboard_type.teachers_requests'))),
            icon: 'fa fa-users',
            path: '/teachers',
          },"requestsNum":_vm.$store.getters['roles/non_active_count']}}):_vm._e(),(_vm.hasPermission('access_teacher'))?_c('sidebar-item',{class:{
            'd-none':
              _vm.hasPermission('admin_schools') ||
              _vm.hasPermission('admin_regions'),
          },attrs:{"link":{
            name: ("" + (this.$t('admin_dashboard_type.existing_teacher'))),
            icon: 'fa fa-users',
            path: '/active_teacher',
          }}}):_vm._e(),(_vm.hasPermission('access_courses'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('courses'))),
            icon: 'fa fa-book',
            path: '/courses',
          }}}):_vm._e(),(_vm.hasPermission('course_auth'))?_c('sidebar-item',{class:{
            'd-none':
              _vm.hasPermission('admin_schools') ||
              _vm.hasPermission('admin_regions'),
          },attrs:{"link":{
            name: ("" + (this.$t('My_Classes'))),
            icon: 'fa fa-book',
            path: '/auth/courses',
          }}}):_vm._e(),(_vm.hasPermission('access_users'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('Group_requests'))),
            icon: 'fa fa-users',
            path: '/course/requests',
          },"requestsNum":_vm.$store.getters['roles/course_count']}}):_vm._e(),(_vm.hasPermission('access_group'))?_c('sidebar-item',{class:{
            'd-none':
              _vm.hasPermission('admin_schools') ||
              _vm.hasPermission('admin_regions'),
          },attrs:{"link":{
            name: ("" + (this.$t('My_Classes'))),
            icon: 'fa fa-users',
            path: '/auth/groups',
          },"requestsNum":_vm.$store.getters['roles/task_count']}}):_vm._e(),(_vm.hasPermission('all_groups'))?_c('sidebar-item',{class:{
            'd-none':
              _vm.hasPermission('admin_schools') ||
              _vm.hasPermission('admin_regions'),
          },attrs:{"link":{
            name: ("" + (this.$t('groups'))),
            icon: 'fa fa-users',
            path: '/groups',
          }}}):_vm._e(),(_vm.hasPermission('access_users'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('users'))),
            icon: 'fa fa-users',
            path: '/users',
          }}}):_vm._e(),_c('sidebar-item',{class:{
            'd-none':
              _vm.hasPermission('admin_schools') ||
              _vm.hasPermission('admin_regions') ||
              _vm.hasPermission('mark_neomi_test'),
          },attrs:{"link":{
            name: ("" + (this.$t('Exams'))),
            icon: 'fa fa-user',
            path: '/exams',
          }}}),(_vm.hasPermission('access_neomi') || _vm.hasPermission('admin_neomi'))?_c('sidebar-item',{class:{
            'd-none': _vm.hasPermission('mark_neomi_test'),
          },attrs:{"link":{
            name: ("" + (this.$t('Neomi Exams'))),
            icon: 'fa fa-user',
            path: '/neomi-exams-two',
          }}}):_vm._e(),(_vm.hasPermission('access_neomi') || _vm.hasPermission('admin_neomi'))?_c('sidebar-item',{class:{
            'd-none': _vm.hasPermission('mark_neomi_test'),
          },attrs:{"link":{
            name: ("" + (this.$t('NEOMI Analytics'))),
            icon: 'fa fa-book',
            path: '/neomi-exams-report',
          }}}):_vm._e(),(_vm.hasPermission('admin_schools'))?_c('sidebar-item',{attrs:{"link":{
            name: "Dashboard",
            icon: 'el-icon-menu',
            path: '/school-dashboard',
          }}}):_vm._e(),(_vm.hasPermission('admin_schools'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('admin_dashboard_type.management_classes'))),
            icon: 'el-icon-office-building',
            path: '/school',
          }}}):_vm._e(),(_vm.hasPermission('admin_schools'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('admin_dashboard_type.count_of_teachers'))),
            icon: 'el-icon-suitcase',
            path: '/school-teachers',
          }}}):_vm._e(),(_vm.hasPermission('admin_schools'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('admin_dashboard_type.count_of_students'))),
            icon: 'el-icon-s-custom',
            path: '/school-students',
          }}}):_vm._e(),(_vm.hasPermission('admin_schools'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('admin_dashboard_type.seats'))),
            icon: 'el-icon-school',
            path: '/assigned-quota',
          }}}):_vm._e(),(_vm.hasPermission('admin_regions'))?_c('sidebar-item',{attrs:{"link":{
            name: "Region Dashboard",
            icon: 'el-icon-menu',
            path: '/region-dashboard',
          }}}):_vm._e(),(_vm.hasPermission('admin_regions'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('admin_dashboard_type.schools'))),
            icon: 'el-icon-office-building',
            path: '/region-schools',
          }}}):_vm._e(),(_vm.hasPermission('admin_regions'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('admin_dashboard_type.seats'))),
            icon: 'el-icon-school',
            path: '/region-seats',
          },"requestsNum":_vm.$store.getters['roles/seat_count']}}):_vm._e(),(_vm.hasPermission('access_dashboard'))?_c('sidebar-item',{attrs:{"link":{
            name: 'Admin Dashboard',
            icon: 'fa fa-user',
            path: '/admin-dashboard',
          }}}):_vm._e(),(_vm.hasPermission('access_dashboard'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('admin_dashboard_type.region_admin'))),
            icon: 'fa fa-user',
            path: '/region-admin',
          }}}):_vm._e(),(_vm.hasPermission('access_dashboard'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('admin_dashboard_type.seats'))),
            icon: 'el-icon-school',
            path: '/admin-seats',
          },"requestsNum":_vm.$store.getters['roles/seat_count']}}):_vm._e(),(_vm.hasPermission('access_users'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('results'))),
            icon: 'fa fa-user',
            path: '/score',
          }}}):_vm._e(),(_vm.hasPermission('access_roles'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('feedback'))),
            icon: 'nc-icon nc-paper-2',
            path: '/feedback',
          }}}):_vm._e(),(_vm.hasPermission('access_roles'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('admin_dashboard_type.photos_from_phones'))),
            icon: 'nc-icon nc-paper-2',
            path: '/photo-gallery',
          }}}):_vm._e(),(_vm.hasPermission('mark_neomi_test'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('exam_correction'))),
            icon: 'fa fa-user',
            path: '/pre-neomi',
          }}}):_vm._e(),(_vm.hasPermission('mark_neomi_test'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('Corrected Exams'))),
            icon: 'fa fa-user',
            path: '/corrected-exams',
          }}}):_vm._e(),(_vm.hasPermission('admin_neomi'))?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('Category Management'))),
            icon: 'fa fa-list',
            path: '/category-management',
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: ("" + (this.$t('logout'))),
            icon: 'fa fa-sign-out',
            path: '/logout',
          }}})],1)],2),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$store.getters['roles/rolesLoad']),expression:"$store.getters['roles/rolesLoad']"}],staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar.apply(null, arguments)}}}),_c('content-footer')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }