import Group from "../../api/groups";

const group = new Group();

const groupModule = {
  namespaced: true,
  state: {
    groups: [],
    courseGroups: [],
    authGroups: [],
    load: false,
    count: 0,
    groupStudents: [],
    studentFeedback: [],
    groupInfo: null,
    score: [],
    getAnswers: [],
    getscores: [],
    questions: [],
    usersCount: [],
    studentscore: null,

    //// NEOMI DATA
    neomiStudents: [],
    neomiGroups: [],
    examResult: [],
    remaining_time: null,
    examScore: null,
    examDetailsInput: null,
    profileCoursesGroups: [],
    coursesDataInGroups: [],
    // Print PDF
    printPdfReportData: {},
    printPdfReportDataNumberTwo: {},
    userDataForPrint: {},
    userDataForPrintTwo: {},
    userDataForPrintAll: {},
    // print 3d
    threeData: {},
    preNeomiData: [],
    markedNeomiData: [],
    // New neomi exam
    newNeomiTwoExamsData: [],
    // New Code for Reports
    examOneReportAnswers: [],
    examOneReportStatus: [],
    examTwoReportAnswers: [],
    examTwoReportAgeGroup: [],
    examOneReportAgeGroup: [],
    examTwoReportStatus: [],
    reportOneCountries: [],
    // Print Group
    printGroupData: [],
    printGroupDataAll: [],
    userDataForPrintAll: [],
    evaluations: [],
  },
  getters: {
    groups: (state) => state.groups,
    load: (state) => state.load,
    count: (state) => state.count,
    usersCount: (state) => state.usersCount,
    courseGroups: (state) => state.courseGroups,
    authGroups: (state) => state.authGroups,
    groupStudents: (state) => state.groupStudents,
    studentFeedback: (state) => state.studentFeedback,
    groupInfo: (state) => state.groupInfo,
    score: (state) => state.score,
    getAnswers: (state) => state.getAnswers,
    getscores: (state) => state.getscores,
    questions: (state) => state.questions,
    studentscore: (state) => state.studentscore,
    //// NEOMI DATA
    neomiExamsData: (state) => state.neomiStudents,
    neomiGroupsData: (state) => state.neomiGroups,
    neomiexamResult: (state) => state.examResult,
    remainingTime: (state) => state.remaining_time,
    getExamScore: (state) => state.examScore,
    getExamDetailsInput: (state) => state.examDetailsInput,
    getProfileCoursesGroups: (state) => state.profileCoursesGroups,
    getCoursesDataInGroups: (state) => state.coursesDataInGroups,
    // Print PDF
    printPdfReportData: (state) => state.printPdfReportData,
    userDataForPrint: (state) => state.userDataForPrint,
    printPdfReportDataNumberTwo: (state) => state.printPdfReportDataNumberTwo,
    userDataForPrintTwo: (state) => state.userDataForPrintTwo,
    userDataForPrintAll: (state) => state.userDataForPrintAll,
    // print 3d
    getThreeData: (state) => state.threeData,
    getNeomiData: (state) => state.preNeomiData,
    getMarkedNeomiData: (state) => state.markedNeomiData,
    getNewNeomiTwoExamsData: (state) => state.newNeomiTwoExamsData,
    // New Code for Reports
    getExamOneReportAnswers: (state) => state.examOneReportAnswers,
    getExamOneReportAgeGroup: (state) => state.examOneReportAgeGroup,
    getExamOneReportStatus: (state) => state.examOneReportStatus,
    getExamTwoReportAnswers: (state) => state.examTwoReportAnswers,
    getExamTwoReportAgeGroup: (state) => state.examTwoReportAgeGroup,
    getExamTwoReportStatus: (state) => state.examTwoReportStatus,
    getReportOneCountries: (state) => state.reportOneCountries,
    // Print Group
    getPrintGroupData: (state) => state.printGroupData,
    getPrintGroupDataAll: (state) => state.printGroupDataAll,
    getEvaluations: (state) => state.evaluations,
  },
  mutations: {
    SET_GROUP(state, groups) {
      state.groups = groups;
    },
    SET_LOAD(state, load) {
      state.load = load;
    },
    SET_COUNT(state, count) {
      state.count = count;
    },
    SET_USERSCOUNT(state, usersCount) {
      state.usersCount = usersCount;
    },
    SET_COURSE_GROUPS(state, courseGroups) {
      state.courseGroups = courseGroups;
    },
    SET_AUTH_GROUP(state, authGroups) {
      state.authGroups = authGroups;
    },
    SET_GROUP_STUDENTS(state, groupStudents) {
      state.groupStudents = groupStudents;
    },
    SET_STUDENT_FEEDBACK(state, studentFeedback) {
      state.studentFeedback = studentFeedback;
    },
    SET_GROUP_INFO(state, groupInfo) {
      state.groupInfo = groupInfo;
    },
    SET_SCORE(state, score) {
      state.score = score;
    },
    SET_QUESTION(state, questions) {
      state.questions = questions;
    },
    GET_SCORE(state, getscores) {
      state.getscores = getscores;
    },
    SET_RESULT(state, result) {
      state.result = result;
    },

    SET_GET_ANSWERS(state, getAnswers) {
      state.getAnswers = getAnswers;
    },
    SET_STUDENTSCORE(state, studentscore) {
      state.studentscore = studentscore;
    },
    //// NEOMI DATA
    SET_NEOMI_EXAMS_SCORES(state, exam) {
      state.neomiStudents = exam;
    },
    SET_NEOMI_GROUPS_DATA(state, exam) {
      state.neomiGroups = exam;
    },
    SET_NEOMI_EXAMS_RESULT(state, exam) {
      state.examResult = exam;
    },
    SET_NEOMI_EXAM_Remaining_Time(state, time) {
      state.remaining_time = time;
    },
    SET_NEOMI_EXAM_SCORE(state, score) {
      state.examScore = score;
    },
    SET_NEOMI_EXAM_DETAILS(state, exam) {
      state.examDetailsInput = exam;
    },
    SET_PROFILE_COURSES_GROUPS(state, items) {
      state.profileCoursesGroups = items;
    },
    SET_DATA_COURSES_GROUPS(state, cor) {
      state.coursesDataInGroups = cor;
    },
    // printPdfReportData
    SET_PRINT_PDF_DATA(state, cor) {
      state.printPdfReportData = cor;
    },
    SET_PRINT_PDF_DATA_NUMBER_TWO(state, cor) {
      state.printPdfReportDataNumberTwo = cor;
    },
    SET_USER_DATA_PRINT(state, cor) {
      state.userDataForPrint = cor;
    },

    SET_USER_DATA_PRINT_TWO(state, cor) {
      state.userDataForPrintTwo = cor;
    },
    SET_USER_DATA_PRINT_ALL(state, cor) {
      state.userDataForPrintAll = cor;
    },
    SET_THREE_DATA_PRINT(state, cor) {
      state.threeData = cor;
    },
    SET_NEOMI_DATA(state, item) {
      state.preNeomiData = item;
    },
    SET_MARKED_NEOMI_DATA(state, item) {
      state.markedNeomiData = item;
    },
    SET_NEW_NEOMI_TWO_EXAMS_DATA(state, item) {
      state.newNeomiTwoExamsData = item;
    },
    // New Code for Reports
    SET_EXAM_ONE_REPORT_ANSWERS(state, items) {
      state.examOneReportAnswers = items;
    },
    SET_EXAM_ONE_REPORT_AGE_GROUP(state,item) {
      state.examOneReportAgeGroup = item;
    },
    SET_EXAM_ONE_REPORT_STATUS(state, items) {
      state.examOneReportStatus = items;
    },
    SET_EXAM_TWO_REPORT_ANSWERS(state, items) {
      state.examTwoReportAnswers = items;
    },
    SET_EXAM_TWO_REPORT_AGE_GROUP(state, items) {
      state.examTwoReportAgeGroup = items;
    },
    SET_EXAM_TWO_REPORT_STATUS(state, items) {
      state.examTwoReportStatus = items;
    },
    SET_REPORT_ONE_COUNTRIES(state, items) {
      state.reportOneCountries = items;
    },
    // Print Group
    SET_PRINT_GROUP_DATA(state, items) {
      state.printGroupData = items;
    },
    // SET_PRINT_GROUP_DATA_ALL(state, items) {
    //   state.printGroupDataAll = items;
    //   console.log("Committing data:", data);
    // },
    SET_PRINT_GROUP_DATA_ALL(state, data) {
      state.printGroupDataAll = Object.keys(data).map((challengeKey) => ({
        challenge: challengeKey,
        users: data[challengeKey].map((user) => ({
          ...user,
          answers: user.answers || [], // Default to empty array if answers are missing
        })),
      }));
    },
    SET_ACTIVITY_EVALUATION(state, evaluation) {
      state.evaluations = [evaluation];
    },

  },
  actions: {
    getGroups({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .list()
          .then((response) => {
            commit("SET_GROUP", response.groups);
            commit("SET_COUNT", response.groups.length);
            commit("SET_PROFILE_COURSES_GROUPS", response.courses);

            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
    addGroup({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .add(data)
          .then((response) => {
            commit("SET_GROUP", response);
            commit("SET_LOAD", false);
            resolve();
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },

    courseGroups({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .get(id)
          .then((response) => {
            commit("SET_COURSE_GROUPS", response.groups);
            commit("SET_LOAD", false);
            resolve();
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },

    addGroupStudent({ commit }, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .addStudent(payload.id, payload.status, {
            promo_code: payload.promo_code,
            user_id: payload.student_id,
          })
          .then((response) => {
            commit("SET_LOAD", false);
            resolve();
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },

    authGroups({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .authGroups(payload.query)
          .then((response) => {
            commit("SET_COUNT", response.count);
            commit("SET_AUTH_GROUP", response.groups);
            commit("SET_DATA_COURSES_GROUPS", response.courses);
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },

    updateGroup({ commit }, payload) {
      commit("SET_LOAD", true);
      return new Promise((resolve, reject) => {
        group
          .update(payload.data, payload.id)
          .then((response) => {
            commit("SET_LOAD", false);
            resolve();
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },

    deleteGroup({ commit }, id) {
      commit("SET_LOAD", true);
      return new Promise((resolve, reject) => {
        group
          .delete(id)
          .then((response) => {
            resolve();
            commit("SET_LOAD", false);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },

    // get group students
    getGroupStudents({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .get_group_students(payload.id, payload.query)
          .then((response) => {
            commit("SET_GROUP_STUDENTS", response.groups);
            commit("SET_STUDENT_FEEDBACK", response.feedback);
            commit("SET_COUNT", response.count);

            commit("SET_LOAD", false);
            resolve(response);
            // console.log(response.groups);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
    score({ commit }, data) {
      return new Promise((resolve, reject) => {
        group
          .score(data)
          .then((response) => {
            commit("SET_SCORE", response);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    questions({ commit }) {
      return new Promise((resolve, reject) => {
        group
          .questions()
          .then((response) => {
            commit("SET_QUESTION", response);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    usersCount({ commit }) {
      return new Promise((resolve, reject) => {
        group
          .usersCount()
          .then((response) => {
            commit("SET_USERSCOUNT", response.users);
            resolve(response.users);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // Start Student Score Used In Exams Page
    studentscore({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .studentscore(id)
          .then((response) => {
            commit("SET_LOAD", true);
            commit("GET_SCORE", response);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    // End Student Score Used In Exams Page
    totalAnswers({ commit }) {
      return new Promise((resolve, reject) => {
        group
          .totalAnswers()
          .then((response) => {
            commit("SET_SCORE", response.questions);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    confirmScore({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .confirmScore(data)
          .then((response) => {
            console.log(data);
            console.log(response);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },

    sendReport({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .sendReport(data)
          .then((response) => {
            console.log(data);
            console.log(response);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },

    setScore({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .setScore(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    // get group info
    getGroupInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .get_group_info(payload.id)
          .then((response) => {
            commit("SET_GROUP_INFO", response.group);
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            commit("SET_LOAD", false);
          });
      });
    },
    getAnswers({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .getAnswers(data)
          .then((response) => {
            //  console.log(data);
            // console.log(response);
            resolve(response);
            //   console.log(response)
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    getStudentScore({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("SET_STUDENTSCORE", true);
        group
          .getStudentScore(data)
          .then((response) => {
            //  console.log(data);
            // console.log(response);
            resolve(response);
            //   console.log(response)
          })
          .catch((err) => {
            commit("SET_STUDENTSCORE", false);
            reject(err);
          });
      });
    },
    //// NEOMI DATA
    // getNeomiGroupsData
    getNeomiExamsData({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .getNeomiStudentsData()
          .then((response) => {
            commit("SET_NEOMI_EXAMS_SCORES", response.user_data);

            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    getNeomiWithGroupsData({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .getNeomiGroupsData()
          .then((response) => {
            commit("SET_NEOMI_GROUPS_DATA", response.groups);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    getNeomiExamResult({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .getNeomiExamData(payload)
          .then((response) => {
            commit("SET_NEOMI_EXAMS_RESULT", response.user_answers);
            commit("SET_NEOMI_EXAM_Remaining_Time", response.remaining_time);

            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },

    getExamResultScore({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .getExamScore(payload)
          .then((response) => {
            commit("SET_NEOMI_EXAM_SCORE", response);
            console.log(response);

            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },

    updateExamResultScore({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .updateExamData(payload)
          .then((response) => {
            commit("SET_LOAD", false);

            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },

    getExamDetailsNeomi({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .getExamDetails(payload)
          .then((response) => {
            commit("SET_NEOMI_EXAM_DETAILS", response.user_details);
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    // redo_nemoi_test
    redoNemoiTest({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .redo_nemoi_test(payload)
          .then((response) => {
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    resetNemoiTimer({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .reset_nemoi_timer(payload)
          .then((response) => {
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    // print_exam_report
    handlerPrintExamReport({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .print_exam_report(payload)
          .then((response) => {
            commit("SET_PRINT_PDF_DATA", response);
            commit("SET_USER_DATA_PRINT", response.user_data);
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    handlerPrintExamReportForMark({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .print_exam_report_mark(payload)
          .then((response) => {
            console.log("print_exam_report_mark",response.user_data);
            commit("SET_PRINT_PDF_DATA_NUMBER_TWO", response);
            commit("SET_USER_DATA_PRINT_TWO", response.user_data);
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    // Submit mark exam report
    handlerSubmitExamReportForMark({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .submit_exam_report_mark(payload)
          .then((response) => {
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    // Submit exam to Ai
    handlerSubmitExamToAi({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .submit_exam_to_ai(payload)
          .then((response) => {
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    // print 3d
    handlerThreeData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .get_three_d_answers(payload)
          .then((response) => {
            // SET_THREE_DATA_PRINT
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    handlerThreeDataEvaluation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .get_three_d_evaluations(payload)
          .then((response) => {
            console.log(response);
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    handlerThreeDataAdvanced({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .get_three_d_advanced(payload)
          .then((response) => {
            console.log(response);
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    handlerGetPreNeomiData({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .getUsersForNeomiMark()
          .then((response) => {
            commit("SET_NEOMI_DATA", response.users);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    handlerGetMarkedNeomiData({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .getUsersNeomiMarked()
          .then((response) => {
            commit("SET_MARKED_NEOMI_DATA", response.users);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    // New neomi exam
    getNewNeomiExamsData({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .getNewNeomiExamData()
          .then((response) => {
            commit("SET_NEW_NEOMI_TWO_EXAMS_DATA", response.groups);

            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    // Nemoi Exams Report
    // New Code for Reports
    handlerGetExamOneReports({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .getNeomiExamOneReport(payload)
          .then((response) => {
            commit("SET_EXAM_ONE_REPORT_ANSWERS", response.answers);
            commit("SET_EXAM_ONE_REPORT_AGE_GROUP", response.age_groups);
            commit("SET_EXAM_ONE_REPORT_STATUS", response.stats);
            commit("SET_REPORT_ONE_COUNTRIES", response.countries);
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    handlerGetExamTwoReports({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .getNeomiExamTwoReport(payload)
          .then((response) => {
            commit("SET_EXAM_TWO_REPORT_ANSWERS", response.answers);
            commit("SET_EXAM_TWO_REPORT_AGE_GROUP", response.age_groups);
            commit("SET_EXAM_TWO_REPORT_STATUS", response.stats);
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    handlerPrintNeomiExamForGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .getPrintNeomiExamForGroup(payload)
          .then((response) => {
            console.log("getPrintNeomiExamForGroup",response.user_data);
            commit("SET_PRINT_GROUP_DATA", response.user_data);

            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    handlerPrintNeomiExamForGroupAll({ commit }, { exam_type, group_id }) {
      commit("SET_LOAD", true);
      return group
        .getPrintNeomiExamForGroupAll({ exam_type, group_id })
        .then((response) => {
          commit("SET_PRINT_GROUP_DATA_ALL", response.all_challenges_data);
          commit("SET_LOAD", false);
          return response;
        })
        .catch((err) => {
          commit("SET_LOAD", false);
          throw err;
        });
    },
    // handlerPrintNeomiExamForGroupAll({ commit }, { exam_type, group_id }) {
    //   return new Promise((resolve, reject) => {
    //     commit("SET_LOAD", true);
    //     group
    //       .getPrintNeomiExamForGroupAll({ exam_type, group_id })
    //       .then((response) => {
    //         console.log("getPrintNeomiExamForGroupAll", response.user_data);
    //         commit("SET_PRINT_GROUP_DATA_ALL", response.user_data);
    //         commit("SET_LOAD", false);
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         commit("SET_LOAD", false);
    //         reject(err);
    //       });
    //   });
    // },
    // handlerPrintNeomiExamForGroupAll({ commit }, { exam_type, group_id }) {
    //   return new Promise((resolve, reject) => {
    //     commit("SET_LOAD", true);
    //     // Pass the exam type and group_id to the method
    //     group
    //       .getPrintNeomiExamForGroupAll({ exam_type, group_id })
    //       .then((response) => {
    //         console.log("getPrintNeomiExamForGroupAll", response.user_data);
    //         commit("SET_PRINT_GROUP_DATA_ALL", response.user_data);
    //         console.log('Received data:', response.data);
    //         commit("SET_LOAD", false);
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         commit("SET_LOAD", false);
    //         reject(err);
    //       });
    //   });
    // },
    // handlerPrintNeomiExamForGroupAll({ commit }, { exam_type, group_id }) {
    //   return new Promise((resolve, reject) => {
    //     commit("SET_LOAD", true);

    //     // Make sure you're calling the right API
    //     group
    //       .getPrintNeomiExamForGroupAll({ exam_type, group_id })
    //       .then((response) => {
    //         console.log("getPrintNeomiExamForGroupAll Response:", response.user_data);

    //         // Ensure the response is in the correct format
    //         if (Array.isArray(response.user_data)) {
    //           commit("SET_PRINT_GROUP_DATA_ALL", response.user_data);
    //         } else {
    //           console.error("Expected an array, but got:", response.user_data);
    //         }

    //         commit("SET_LOAD", false);
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         commit("SET_LOAD", false);
    //         reject(err);
    //       });
    //   });
    // },
    // Delete Exam Answer When Correct
    deleteExamAnswerWhenCorrect({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .delete_exam_answer_when_correct(payload)
          .then((response) => {
            commit("SET_LOAD", false);
            resolve(response);
          })
          .catch((err) => {
            commit("SET_LOAD", false);
            reject(err);
          });
      });
    },
    saveActivityEvaluation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        group
          .save_activity_evaluation(payload)
          .then((response) => {
            if (response.data && response.status === 200) {
              commit("SET_ACTIVITY_EVALUATION", response.data);
            }
            resolve(response);
          })
          .catch((err) => {
            console.error('Error saving evaluation:', err.response?.data || err);
            throw err;
          })
          .finally(() => {
            commit("SET_LOAD", false);
          });
      });
    },

  },
};

export default groupModule;
