import Category from "../../api/category"
const category = new Category();

const categoryModule = {
  namespaced: true,
  state:{
    // To track loading state
    loading: false,
    // To store any errors
    errors : [],
    // store search results
    results: [],
    categories: [],
  },
  getters:{
    isLoading: (state) => state.loading,
    getErrors: (state) => state.errors,
    getResults: (state) => state.results,
    getCategories: (state) => state.categories,
  },
  mutations:{
    SET_LOADING(state, status) {
      state.loading = status;
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
    SET_RESULTS(state, results) {
      console.log("Setting Search Results in Vuex:", results);
      state.results = results;
    },

    SET_CATEGORIES(state, categories) {
      console.log("Setting Categories in Vuex:", categories);
      state.categories = categories.map(category => ({
        id: category.id,
        name: category.category || 'No Category Name',
        category_counter: category.category_counter || 0,
        subcategories: category.subcategories?.map(subcategory => ({
          // id: subcategory.id,
          name: subcategory.subcategory || 'No Subcategory Name',
          subcategory_counter: subcategory.subcategory_counter || 0
        })) || [],
      }));
    },

    REMOVE_CATEGORY(state,categoryId){
      state.categories = state.categories.filter(category => category.id !== categoryId );
    }
  },
  actions:{
    // Action to manage categories, subcategories, and words
    async categoryManagement({commit},payload){
      commit("SET_LOADING", true);
      commit("SET_ERRORS", null);
      try{
        const response = await category.categoryManagement(payload);
        return response;
      }catch (error){
        commit("SET_ERRORS", error.response?.data || error.message);
        throw error;
      }finally{
        commit("SET_LOADING", false);
      }
    },
    // Action to search word
    async searchWord({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_ERRORS", null);
      try {
        const response = await category.searchWord(payload);
        console.log("API Response:", response);
        // Check for errors in the response
        if (response && response.error) {
          commit("SET_ERRORS", response.error);
          throw new Error(response.error);
        }

        // Set the results as received from the backend
        commit("SET_RESULTS", response || []);
        return response;
      } catch (error) {
        commit("SET_ERRORS", error.response?.data || error.message);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },
    // Action to import words from Excel
    async importWords({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_ERRORS", null);
      try {
        const response = await category.importWords(payload);
        return response;
      } catch (error) {
        commit("SET_ERRORS", error.response?.data || error.message);
        throw error;
      }finally {
        commit("SET_LOADING", false);
      }
    },

    // Action to show categories and subcategories
    async showCategoriesAndSubcategories({ commit }) {
      commit("SET_LOADING", true);
      commit("SET_ERRORS", null);

      try {
        const response = await category.showCategoriesAndSubcategories();

        console.log("Full API Response:", response);
        console.log("API Data:", response.data);

        commit("SET_CATEGORIES", response);
        return response;

      } catch (error) {
        console.error("API Error:", error.message);
        commit("SET_ERRORS", error.message);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },
    // Action to delete category,subcategory,words
    async deleteCategory({commit},categoryId){
      commit("SET_LOADING",true);
      commit("SET_ERRORS",false);
      try{
        await category.delete(categoryId);
        commit("REMOVE_CATEGORY",categoryId);
      }
      catch{
        commit("SET_ERRORS",error?.response.data || error.message);
        throw error;
      }
      finally{
        commit("SET_LOADING",false);
      }
      }
    }
};
export default categoryModule;
