<template>
  <!-- The sidebar container with dynamic attributes and styles -->
  <div
    class="sidebar"
    :style="sidebarStyle"
    :data-color="backgroundColor"
    :data-image="backgroundImage"
    :data-active-color="activeColor"
  >
    <!-- The main wrapper for the sidebar; referenced by PerfectScrollbar -->
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <!-- Logo section: includes a small circular mini-logo and a text logo -->
      <div class="logo">
        <a class="logo-mini" href="/">
          <div
            class="bg-white d-flex"
            style="height: 2.5rem; width: 2.5rem; border-radius: 50%"
          >
            <!-- Display the logo image -->
            <img :src="logo" class="w-100" alt="logo" />
          </div>
        </a>
        <!-- Text logo -->
        <a href="/" class="simple-text logo-normal">
          Just To Learn
        </a>
      </div>

      <!-- Default slot for any additional content you want to place in the sidebar -->
      <slot></slot>

      <!-- Sidebar navigation menu -->
      <ul class="nav">
        <!-- Slot for sidebar link items; by default iterates over `sidebarLinks` prop -->
        <slot name="links">
          <!-- Render each link using a custom sidebar-item component -->
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <!-- If a link has children, recursively render them -->
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

/**
 * Utility function to check if elements with a given class name exist in the DOM.
 * @param {String} className - The class name to look for.
 * @returns {Boolean}
 */
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

/**
 * Initialize PerfectScrollbar on an element with a given class name.
 * If the element doesn't exist yet, it retries after 100ms (useful for async loading).
 * @param {String} className - The class name of the element where we want the scrollbar.
 */
function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  name: "sidebar",
  // Props to configure the sidebar (colors, images, logo, links, etc.)
  props: {
    title: {
      type: String,
      default: "أكاديمية فقط لنتعلم",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = [
          "",
          "blue",
          "azure",
          "green",
          "orange",
          "red",
          "purple",
          "black",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    backgroundImage: {
      type: String,
      default: "/static/img/sidebar-5.jpg",
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    logo: {
      type: String,
      default: "/static/img/vue-logo.png",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  // Provide the `autoClose` prop to child components through Vue's dependency injection
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  computed: {
    /**
     * Compute the inline style object for the sidebar background image.
     */
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
  },
  methods: {
    /**
     * Initializes PerfectScrollbar if on Windows, and modifies body classes accordingly.
     */
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");

      if (isWindows) {
        // If the OS is Windows, activate PerfectScrollbar for .sidebar-wrapper
        initScrollbar("sidebar-wrapper");
        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
  },
  // Lifecycle hook: called after the component is mounted, initiates the scrollbar
  mounted() {
    this.initScrollbar();
  },
};
</script>

<style>


/* Force normal case (no uppercase) for sidebar text */
.sidebar .nav p,
.sidebar .nav .sidebar-normal {
  text-transform: none !important;
}
</style>
