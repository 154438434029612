<template>
  <!-- is_local => Shows a simpler version of the table with local pagination and local sorting. -->
  <card v-if="is_local">
    <!-- Actions slot, typically for adding new items, etc. -->
    <div class="col-12 d-flex justify-content-end pb-2">
      <slot name="action"></slot>
    </div>
    
    <!-- Main table when data is handled locally. -->
    <el-table
      odd
      stripe
      style="width: 100%"
      @sort-change="setLocalSort"
      :data="pagedData"
      :row-class-name="class_name"
      :cell-class-name="class_name"
      v-loading="loading"
    >
      <slot name="columns"></slot>
    </el-table>

    <!-- Footer / pagination section -->
    <div
      slot="footer"
      class="col-12 d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap pt-2"
    >
      <div class="d-flex">
        <!-- Items per page selector -->
        <el-select
          class="select-default mb-3"
          style="width: 100px"
          label="SHOW"
          v-model="pagination.perPage"
          placeholder="Per page"
        >
          <el-option
            class="select-default"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </div>
      <l-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="count"
      />
    </div>
  </card>

  <!-- Standard Table Layout (for non-local data) -->
  <card v-else>
    <div>
      <div class="d-flex justify-content-between pb-2">
        <h1 class="h2 m-0">{{ title }}</h1>

        <!-- Dropdown for various actions like Export, Bulk, etc. -->
        <el-dropdown @command="handleCommand" v-if="action">
          <l-button type="info" style="font-size: 1rem">
            <!-- Display progress if there's an ongoing import/export -->
            {{
              progress_count && progress_count != 100
                ? `${Math.ceil(progress_count)} %`
                : ""
            }}
            {{ $t("options") }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </l-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="Export" v-if="enable_export">
              <el-icon class="el-icon-download"></el-icon>
              {{ $t("export") }}
            </el-dropdown-item>
            <el-dropdown-item>
              <slot name="action"></slot>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <!-- Filter section (wrapped in el-collapse for toggling) -->
      <el-collapse class="w-100 p-4" v-if="useFilter">
        <el-collapse-item name="1">
          <template #title>
            <div class="d-flex justify-content-between align-items-center w-100">
              <p class="mb-0">{{ $t("Search_and_filter") }}</p>
              <l-button
                type="info"
                class="p-2 mr-3 mb-0"
                @click="resetFunction"
              >
                {{ $t("Reset") }}
              </l-button>
            </div>
          </template>
          <el-row :gutter="10" class="w-100 m-0">
            <!-- Status filter -->
            <el-col :md="12">
              <el-select
                v-if="!hidden_status"
                v-model="removed"
                :placeholder="$t('Global.status')"
                class="select-default mt-2 w-100"
              >
                <el-option
                  v-for="(state, i) in status"
                  :key="i"
                  :label="state.label"
                  :value="state.value"
                  class="text-right mr-3"
                />
              </el-select>
            </el-col>

            <!-- Search filter -->
            <el-col :md="12">
              <el-input
                ref="searchInput"
                class="mt-2 d-block d-lg-inline-block w-100"
                :placeholder="$t('Global.search')"
                v-model="search"
                @keyup.enter.native="Refresh(true)"
              />
            </el-col>

            <!-- Additional filters passed as slot -->
            <el-col :md="!hidden_status ? 24 : 12">
              <slot name="filter"></slot>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>

      <!-- Main data table for remote data (API-driven) -->
      <el-table
        odd
        stripe
        fit
        @sort-change="sortChange"
        @selection-change="selection_change"
        :cell-class-name="class_name"
        style="width: 100%"
        :data="is_local ? pagedData : DataItems"
      >
        <slot name="columns"></slot>
      </el-table>
    </div>

    <!-- Table Footer -->
    <div
      slot="footer"
      class="col-12 d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap pt-2"
    >
      <!-- Items per page selector -->
      <div>
        <span style="color: #9a9a9a; font-size: 0.75rem" class="mr-1 mt-1 text-right">
          {{ $t("show") }} &nbsp;
        </span>
        <el-select
          class="select-default mb-3"
          style="width: 100px"
          label="SHOW"
          v-model="pagination.perPage"
          placeholder="Per page"
        >
          <el-option
            class="select-default"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
        <span
          style="color: #9a9a9a; font-size: 0.75rem; margin-right: 10px"
          class="ml-1 mt-1"
        >
          {{ $t("item_per_page") }}
        </span>
      </div>

      <!-- Pagination -->
      <div class="d-flex">
        <l-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="count"
        />
      </div>
    </div>
  </card>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";

/**
 * Filters out empty values from an object.
 * @param {Object} obj - The object to filter.
 * @returns {Object} - A filtered object with non-empty values only.
 */
function filterObject(obj = {}) {
  const result = {};
  for (const key in obj) {
    if (obj[key] !== "") {
      result[key] = obj[key];
    }
  }
  return result;
}

export default {
  name: "DataTable",
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    /**
     * Whether pagination should be hidden completely.
     */
    hidden_pagination: { default: false, type: Boolean },

    /**
     * Main data array for table content.
     */
    DataItems: {
      type: Array,
      default: () => [],
    },

    /**
     * URL for importing data (if needed).
     */
    import_url: String,

    /**
     * An external filter object used for advanced filtering.
     */
    filter: {
      type: Object,
      default: () => ({}),
    },

    /**
     * CSS class name applied to each row/cell in the table (Element UI property).
     */
    class_name: String,

    /**
     * Whether to show the action dropdown or not.
     */
    action: {
      default: true,
      type: Boolean,
    },

    /**
     * Whether to display the filter section (Search and other filters).
     */
    useFilter: {
      default: true,
      type: Boolean,
    },

    /**
     * Whether exporting data is allowed or not.
     */
    enable_export: {
      default: true,
      type: Boolean,
    },

    /**
     * Title of the table/card.
     */
    title: String,

    name: String,

    /**
     * Whether to show a loading spinner over the table.
     */
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     * Total count of items (remote or local).
     */
    count: {
      type: Number,
      default: 0,
    },

    /**
     * If true, use local data pagination and sorting; otherwise fetch from API.
     */
    is_local: {
      default: false,
      type: Boolean,
    },

    /**
     * If true, hides the status filter (removed/active).
     */
    hidden_status: {
      default: false,
      type: Boolean,
    },

    fileLoading: Boolean,
    allow_bulk_process: Boolean,
    admin_permission: String,
    edit_permission: String,
  },
  data() {
    return {
      /**
       * Pagination parameters:
       * - perPage: how many items per page.
       * - currentPage: current page number.
       * - perPageOptions: allowable page sizes.
       */
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
      },

      /**
       * Sorting parameters:
       * - column: name of the property being sorted
       * - asc: whether the sort is ascending (true) or descending (false)
       */
      sort: { column: "id", asc: true },

      /**
       * If data is locally sorted, we store the result here.
       */
      locallySortedData: [],

      /**
       * Tracks ongoing progress for import/export, e.g., 60% done.
       */
      progress_count: null,

      /**
       * Status filters (for demonstration).
       */
      status: [
        { value: 3, label: this.$t("hidden") },
        { value: 2, label: this.$t("non-active") },
        { value: 1, label: this.$t("active") },
      ],

      /**
       * Simple search text.
       */
      search: "",

      /**
       * 'removed' or 'status' filter example.
       */
      removed: null,

      /**
       * Local loading state, set to true until data is fully fetched.
       */
      isLoading: true,
    };
  },
  computed: {
    /**
     * Derived import URL based on base environment path and `import_url`.
     */
    import_src() {
      return `${process.env.VUE_APP_BASE_URL}/${this.import_url}`;
    },

    /**
     * Returns the subset of DataItems for the current page (local pagination).
     */
    pagedData() {
      return this.DataItems.slice(this.from, this.to);
    },

    /**
     * Index offset to slice the DataItems array up to `to - 1`.
     */
    to() {
      let highBound = this.from + this.pagination.perPage;
      // If total data is less than the computed highBound, adjust it
      if (this.count < highBound) {
        highBound = this.count;
      }
      return highBound;
    },

    /**
     * Index offset to slice the DataItems array from `from`.
     */
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  mounted() {
    // Trigger an initial fetch (if not local) when component mounts:
    const query = {
      limit: this.pagination.perPage,
      offset: 0,
    };
    this.$emit("Refresh", query);
  },
  watch: {
    /**
     * When the current page changes, push it to the router query (for deep-linking),
     * then refresh remote data if not using local mode.
     */
    "pagination.currentPage"() {
      this.$router.push({ query: this.pagination });
      if (!this.is_local) {
        this.Refresh();
      }
    },

    /**
     * When the items-per-page changes, push it to router and refresh data.
     */
    "pagination.perPage"() {
      this.$router.push({ query: this.pagination });
      this.Refresh();
    },

    /**
     * Instead of watching each filter key individually,
     * we do a deep watch on `filter` and call Refresh whenever it changes.
     */
    filter: {
      deep: true,
      handler() {
        this.Refresh();
      },
    },

    /**
     * Watch `removed` filter separately (if needed).
     */
    removed() {
      this.Refresh();
    },

    /**
     * When DataItems changes, hide loading spinner.
     */
    DataItems() {
      this.isLoading = false;
    },
  },
  methods: {
    /**
     * Refresh data from the parent or store, triggered after pagination/filter changes.
     * @param {Boolean} search - Whether this is specifically a search refresh (resets offset to 0).
     */
    Refresh(search = false) {
      this.isLoading = true;

      // Build the query object
      const query = this.is_local
        ? {
            limit: this.pagination.perPage,
            offset:
              (this.pagination.currentPage - 1) * this.pagination.perPage,
          }
        : {
            limit: this.pagination.perPage,
            offset: search
              ? 0
              : (this.pagination.currentPage - 1) * this.pagination.perPage,
            search_text: this.search,
            order_by: this.sort.column ? this.sort.column : "id",
            order_direction: this.sort.asc ? "ASC" : "DESC",
            status: this.removed,
            ...filterObject(this.filter),
          };

      // Persist the filter in store (if needed).
      this.$store.commit("SET_FILTER", this.filter);

      // Emit the query to the parent component (or for an API call).
      this.$emit("Refresh", query);

      // Reset progress bar for import/export
      this.progress_count = null;
    },

    /**
     * Handle commands from the actions dropdown (e.g., export).
     * @param {Function} command - A function passed through the slot or mapped from an action.
     */
    handleCommand(command) {
      if (command) command();
    },

    /**
     * Track progress of import/export uploading/downloading.
     * @param {Object} e - Event containing progress percent.
     */
    progress(e) {
      this.isLoading = true;
      this.progress_count = e.percent;
      console.log("Progress:", this.progress_count);
    },

    /**
     * Emit a selection event with the currently selected table rows.
     * Useful for bulk operations.
     * @param {Array} selections - An array of selected row objects.
     */
    selection_change(selections) {
      this.$emit("selection", selections);
    },

    /**
     * On remote-based table: handle sorting changes from table UI.
     * @param {Object} val - Sorting info: { prop: string, order: 'ascending'|'descending' }
     */
    sortChange(val) {
      this.sort.column = val.prop;
      // In Element-UI, 'ascending' or 'descending' are possible values
      this.sort.asc = val.order === "ascending";
      this.Refresh();
    },

    /**
     * On local-based table: apply local sorting logic to the currently paged data.
     * @param {Object} val - Sorting info.
     */
    setLocalSort(val) {
      this.sort.column = val.prop;
      this.sort.asc = val.order === "ascending";
      const prop = val.prop;

      // Sort the data in-place for the current page only
      this.locallySortedData = [...this.pagedData].sort((a, b) => {
        if (a[prop] === b[prop]) return 0;
        // Basic comparison (enhance if you need numeric vs. string sorts)
        if (this.sort.asc) {
          return a[prop] > b[prop] ? 1 : -1;
        } else {
          return a[prop] < b[prop] ? 1 : -1;
        }
      });
    },

    /**
     * Emit an "export" event with query parameters.
     */
    Export() {
      const query = this.useFilter
        ? {
            search_text: this.search,
            removed: this.removed,
            ...filterObject(this.filter),
          }
        : {
            search_text: this.search,
            removed: this.removed,
          };
      this.$emit("export", query);
    },

    /**
     * Emit an event to export in bulk (if needed).
     */
    Export_Bulk() {
      this.$emit("Export_Bulk");
    },

    /**
     * Emit an event to import data in bulk (if needed).
     */
    emitImportBulk() {
      this.$emit("addBulk");
    },

    /**
     * Resets the search, status, and filter, then refreshes data.
     */
    resetFunction() {
      this.search = "";
      this.removed = null;
      this.$emit("reset");
      this.Refresh(false);
    },
  },
};
</script>

<style lang="scss">
/* Example of some scoped or global SCSS styling */

.custom_expand {
  cursor: pointer !important;
}

.resetButton .el-button.is-round:focus,
.resetButton .el-button.is-round:hover,
.resetButton .el-button.is-round:active {
  border-color: #173f5f;
  color: #173f5f;
  background-color: #173f5f;
}

.base_color {
  opacity: 0.9;
  color: #fff;
  background-color: #173f5f;
}

.resetHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  margin: 0.5rem 0rem;
  .el-button {
    margin: 0rem 0.7rem;
  }
}

.resetButton {
  color: #fff;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  margin-left: 1rem;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.el-table__row--striped {
  background-color: #fff !important;
}

.el-table--border,
.el-table--group {
  border: unset !important;
}

.el-dropdown-menu__item {
  text-align: center;
}
</style>
