import Resource from "../api/resource";
import request from "../utils/request";

class Category extends Resource{
  constructor() {
    super("category");
  }
  // add categories,subcategories,words
  categoryManagement(payload){
    return request({
      url:`/category/category-management`,
      method:"post",
      data:payload
    })
  }
  // Search Word
  searchWord(payload) {
    return request({
      url: `/category/search-word`,
      method: "post",
      data: payload,
    });
  }
  // Import Words from Excel
  importWords(payload) {
    return request({
      url: `/category/import-words`,
      method: "post",
      data: payload,
    });
  }
  // Show Categories and Subcategories
  showCategoriesAndSubcategories() {
    return request({
      url: `/category/categories-and-subcategories`,
      method: "get",
    });
  }
  delete(category_id){
    return request({
      url:`/category/delete-category/${category_id}`,
      method:"delete",
    });
  }
}
export { Category as default };
