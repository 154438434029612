import Sidebar from './SideBar.vue'
import SidebarItem from './SidebarItem.vue'

const SidebarStore = {
  showSidebar: false,
  sidebarLinks: [],
  isMinimized: false,
  displaySidebar (value) {
    this.showSidebar = value
  },
  toggleMinimize () {
    document.body.classList.toggle('sidebar-mini')
    // simulate window resize so charts, etc. can update in realtime
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event('resize'))
    }, 180)

    // stop simulation after animations complete
    setTimeout(() => {
      clearInterval(simulateWindowResize)
    }, 1000)

    this.isMinimized = !this.isMinimized
  }
}

const SidebarPlugin = {
  install (Vue, options) {
    // If the user passed sidebarLinks in the plugin options, store them
    if (options && options.sidebarLinks) {
      SidebarStore.sidebarLinks = options.sidebarLinks
    }

    // Make `sidebarStore` available to all components via $root
    Vue.mixin({
      data () {
        return {
          sidebarStore: SidebarStore
        }
      }
    })

    // Add a convenient $sidebar property to all Vue instances
    Object.defineProperty(Vue.prototype, '$sidebar', {
      get () {
        return this.$root.sidebarStore
      }
    })

    // Globally register your sidebar components
    Vue.component('side-bar', Sidebar)
    Vue.component('sidebar-item', SidebarItem)
  }
}

export default SidebarPlugin
