<template>
  <div :class="{ 'd-none': checkRoute || checkRouteTwo }">
    <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
      <notifications></notifications>
      <side-bar>
        <!--            <user-menu></user-menu>-->
        <!--            <mobile-menu></mobile-menu>-->
        <template slot="links">
          <!-- main page  -->
          <sidebar-item
            v-if="hasPermission('access_dashboard')"
            :link="{
              name: `${this.$t('main_page')}`,
              icon: 'nc-icon nc-chart-pie-35',
              path: '/overview',
            }"
          ></sidebar-item>
          <!-- profile page  -->
          <sidebar-item
            v-if="auth()"
            :link="{
              name: `${auth().name}`,
              icon: 'fa fa-user',
              path: '/profile',
            }"
          >
          </sidebar-item>
          <!-- new teachers  -->
          <sidebar-item
            v-if="hasPermission('access_users')"
            :link="{
              name: `${this.$t('admin_dashboard_type.teachers_requests')}`,
              icon: 'fa fa-users',
              path: '/teachers',
            }"
            :requestsNum="$store.getters['roles/non_active_count']"
          ></sidebar-item>
          <!-- teachers   -->
          <sidebar-item
            :class="{
              'd-none':
                hasPermission('admin_schools') ||
                hasPermission('admin_regions'),
            }"
            v-if="hasPermission('access_teacher')"
            :link="{
              name: `${this.$t('admin_dashboard_type.existing_teacher')}`,
              icon: 'fa fa-users',
              path: '/active_teacher',
            }"
          ></sidebar-item>
          <!-- courses  -->
          <sidebar-item
            v-if="hasPermission('access_courses')"
            :link="{
              name: `${this.$t('courses')}`,
              icon: 'fa fa-book',
              path: '/courses',
            }"
          ></sidebar-item>
          <!-- MY courses  -->
          <sidebar-item
            :class="{
              'd-none':
                hasPermission('admin_schools') ||
                hasPermission('admin_regions'),
            }"
            v-if="hasPermission('course_auth')"
            :link="{
              name: `${this.$t('My_Classes')}`,
              icon: 'fa fa-book',
              path: '/auth/courses',
            }"
          ></sidebar-item>
          <!-- group requests  -->
          <sidebar-item
            v-if="hasPermission('access_users')"
            :link="{
              name: `${this.$t('Group_requests')}`,
              icon: 'fa fa-users',
              path: '/course/requests',
            }"
            :requestsNum="$store.getters['roles/course_count']"
          ></sidebar-item>

          <!-- my students  -->
          <!-- <sidebar-item
          v-if="hasPermission('student_auth')"
          :link="{
            name: 'طلابي',
            icon: 'fa fa-users',
            path: '/auth/student',
          }"
        ></sidebar-item> -->
          <!-- my group  -->
          <sidebar-item
            :class="{
              'd-none':
                hasPermission('admin_schools') ||
                hasPermission('admin_regions'),
            }"
            v-if="hasPermission('access_group')"
            :link="{
              name: `${this.$t('My_Classes')}`,
              icon: 'fa fa-users',
              path: '/auth/groups',
            }"
            :requestsNum="$store.getters['roles/task_count']"
          ></sidebar-item>
          <!-- groups  -->
          <sidebar-item
            :class="{
              'd-none':
                hasPermission('admin_schools') ||
                hasPermission('admin_regions'),
            }"
            v-if="hasPermission('all_groups')"
            :link="{
              name: `${this.$t('groups')}`,
              icon: 'fa fa-users',
              path: '/groups',
            }"
          ></sidebar-item>
          <!-- users  -->
          <sidebar-item
            v-if="hasPermission('access_users')"
            :link="{
              name: `${this.$t('users')}`,
              icon: 'fa fa-users',
              path: '/users',
            }"
          ></sidebar-item>
          <!-- roles  -->
          <!-- <sidebar-item
          v-if="hasPermission('access_roles')"
          :link="{
            name: `${this.$t('Roles')}`,
            icon: 'fa fa-cog',
            path: '/roles',
          }"
        ></sidebar-item> -->
          <!-- messages  -->
          <!-- <sidebar-item
          :class="{
            'd-none':
              hasPermission('admin_schools') || hasPermission('admin_regions'),
          }"
          :link="{
            name: `${this.$t('messages')}`,
            icon: 'fa fa-comments',
            path: '/message',
          }"
        ></sidebar-item> -->

          <!-- exams -->

          <sidebar-item
            :class="{
              'd-none':
                hasPermission('admin_schools') ||
                hasPermission('admin_regions') ||
                hasPermission('mark_neomi_test'),
            }"
            :link="{
              name: `${this.$t('Exams')}`,
              icon: 'fa fa-user',
              path: '/exams',
            }"
          >
          </sidebar-item>
          <!-- Start Exams Neomi -->
          <!-- v-if="hasPermission('access_users') || hasRole('teacher')" -->
          <!-- <sidebar-item
            v-if="hasPermission('access_neomi') || hasPermission('admin_neomi')"
            :link="{
              name: `${this.$t('Neomi Exams')}`,
              icon: 'fa fa-user',
              path: '/neomi-exams',
            }"
            :class="{
              'd-none': hasPermission('mark_neomi_test'),
            }"
          >
          </sidebar-item> -->
          <!-- New Neomi Test -->
          <sidebar-item
            v-if="hasPermission('access_neomi') || hasPermission('admin_neomi')"
            :link="{
              name: `${this.$t('Neomi Exams')}`,
              icon: 'fa fa-user',
              path: '/neomi-exams-two',
            }"
            :class="{
              'd-none': hasPermission('mark_neomi_test'),
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="hasPermission('access_neomi') || hasPermission('admin_neomi')"
            :link="{
              name: `${this.$t('NEOMI Analytics')}`,
              icon: 'fa fa-book',
              path: '/neomi-exams-report',
            }"
            :class="{
              'd-none': hasPermission('mark_neomi_test'),
            }"
          >
          </sidebar-item>

          <!-- Start School Admin -->
          <sidebar-item
            v-if="hasPermission('admin_schools')"
            :link="{
              name: `Dashboard`,
              icon: 'el-icon-menu',
              path: '/school-dashboard',
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="hasPermission('admin_schools')"
            :link="{
              name: `${this.$t('admin_dashboard_type.management_classes')}`,
              icon: 'el-icon-office-building',
              path: '/school',
            }"
          >
          </sidebar-item>
          <!-- <sidebar-item
          v-if="hasPermission('admin_schools')"
          :link="{
            name: `التقارير`,
            icon: 'el-icon-reading',
            path: '/school-reports',
          }"
        >
        </sidebar-item> -->
          <sidebar-item
            v-if="hasPermission('admin_schools')"
            :link="{
              name: `${this.$t('admin_dashboard_type.count_of_teachers')}`,
              icon: 'el-icon-suitcase',
              path: '/school-teachers',
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="hasPermission('admin_schools')"
            :link="{
              name: `${this.$t('admin_dashboard_type.count_of_students')}`,
              icon: 'el-icon-s-custom',
              path: '/school-students',
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="hasPermission('admin_schools')"
            :link="{
              name: `${this.$t('admin_dashboard_type.seats')}`,
              icon: 'el-icon-school',
              path: '/assigned-quota',
            }"
          >
          </sidebar-item>
          <!-- End School Admin -->

          <!-- Start Region School Admin  -->
          <sidebar-item
            v-if="hasPermission('admin_regions')"
            :link="{
              name: `Region Dashboard`,
              icon: 'el-icon-menu',
              path: '/region-dashboard',
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="hasPermission('admin_regions')"
            :link="{
              name: `${this.$t('admin_dashboard_type.schools')}`,
              icon: 'el-icon-office-building',
              path: '/region-schools',
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="hasPermission('admin_regions')"
            :link="{
              name: `${this.$t('admin_dashboard_type.seats')}`,
              icon: 'el-icon-school',
              path: '/region-seats',
            }"
            :requestsNum="$store.getters['roles/seat_count']"
          >
          </sidebar-item>
          <!-- End Region School Admin -->
          <!-- //// Start Admin -->
          <sidebar-item
            v-if="hasPermission('access_dashboard')"
            :link="{
              name: 'Admin Dashboard',
              icon: 'fa fa-user',
              path: '/admin-dashboard',
            }"
          ></sidebar-item>
          <sidebar-item
            v-if="hasPermission('access_dashboard')"
            :link="{
              name: `${this.$t('admin_dashboard_type.region_admin')}`,
              icon: 'fa fa-user',
              path: '/region-admin',
            }"
          ></sidebar-item>
          <sidebar-item
            v-if="hasPermission('access_dashboard')"
            :link="{
              name: `${this.$t('admin_dashboard_type.seats')}`,
              icon: 'el-icon-school',
              path: '/admin-seats',
            }"
            :requestsNum="$store.getters['roles/seat_count']"
          >
          </sidebar-item>
          <!--//// End Admin -->
          <sidebar-item
            v-if="hasPermission('access_users')"
            :link="{
              name: `${this.$t('results')}`,
              icon: 'fa fa-user',
              path: '/score',
            }"
          ></sidebar-item>

          <!-- <sidebar-item
            v-if="hasPermission('access_users')"
          :link="{
            name:'معرض الصور',
            icon: 'fa fa-user',
            path: '/gallery',
          }"
        >

        </sidebar-item> -->
          <sidebar-item
            v-if="hasPermission('access_roles')"
            :link="{
              name: `${this.$t('feedback')}`,
              icon: 'nc-icon nc-paper-2',
              path: '/feedback',
            }"
          ></sidebar-item>
          <sidebar-item
            v-if="hasPermission('access_roles')"
            :link="{
              name: `${this.$t('admin_dashboard_type.photos_from_phones')}`,
              icon: 'nc-icon nc-paper-2',
              path: '/photo-gallery',
            }"
          ></sidebar-item>

          <!--  start just for expert exam correction -->
          <sidebar-item
            v-if="hasPermission('mark_neomi_test')"
            :link="{
              name: `${this.$t('exam_correction')}`,
              icon: 'fa fa-user',
              path: '/pre-neomi',
            }"
          ></sidebar-item>
          <sidebar-item
            v-if="hasPermission('mark_neomi_test')"
            :link="{
              name: `${this.$t('Corrected Exams')}`,
              icon: 'fa fa-user',
              path: '/corrected-exams',
            }"
          ></sidebar-item>
          <!-- end just for expert exam correction -->
          <sidebar-item
              v-if="hasPermission('admin_neomi')"
              :link="{
              name: `${this.$t('Category Management')}`,
              icon: 'fa fa-list',
              path: '/category-management',
            }"
          ></sidebar-item>

          <!-- logout  -->
          <sidebar-item
            :link="{
              name: `${this.$t('logout')}`,
              icon: 'fa fa-sign-out',
              path: '/logout',
            }"
          ></sidebar-item>
        </template>
      </side-bar>
      <div class="main-panel" v-loading="$store.getters['roles/rolesLoad']">
        <top-navbar></top-navbar>
        <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

        <content-footer></content-footer>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
// import PerfectScrollbar from 'perfect-scrollbar';
// import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    // UserMenu
  },

  watch: {
    $route(to, from) {
      if (this.auth() && this.auth().id) {
        this.$store.dispatch(
          "roles/getAuthUserRolesPermissions",
          this.auth().id
        );
      }
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },

    initScrollbar() {
      // let docClasses = document.body.classList;
      // let isWindows = navigator.platform.startsWith('Win');
      // if (isWindows) {
      //   // if we are on windows OS we activate the perfectScrollbar function
      //   initScrollbar('main-panel');
      //
      //   docClasses.add('perfect-scrollbar-on');
      // } else {
      //   docClasses.add('perfect-scrollbar-off');
      // }
    },
  },

  mounted() {
    // this.initScrollbar()
    if (this.auth()) {
      this.$store.dispatch("roles/getAuthUserRolesPermissions", this.auth().id);
    }
  },
  computed: {
    checkRoute() {
      return this.$route.path.includes("/image");
    },
    checkRouteTwo() {
      return this.$route.path.includes("/img");
    },
  },
};
</script>

<style>
.swal2-container.swal2-bottom,
.swal2-container.swal2-center,
.swal2-container.swal2-top {
  grid-template-columns: auto minmax(0, 1fr) auto;
  z-index: 100000;
}
</style>

<style scoped>
.main-panel {
  background: rgba(203, 203, 210, 0.15) !important;
  height: auto !important;
}
</style>
