import Resource from "../api/resource";
import request from "../utils/request";

class Group extends Resource {
  constructor() {
    super("group");
  }

  list() {
    return request({
      url: `/groups/my_groups`,
      method: "post",
    });
  }

  add(resource) {
    return request({
      url: `/groups/add_group`,
      method: "post",
      data: resource,
    });
  }

  get(id) {
    return request({
      url: `/groups/course_groups/${id}`,
      method: "get",
    });
  }

  addStudent(id, status, resource) {
    console.log(resource);
    return request({
      url: `/groups/add/${id}/${status}`,
      method: "post",
      data: resource,
    });
  }

  authGroups(query) {
    return request({
      url: `/groups/student_groups`,
      method: "post",
      params: query,
    });
  }

  update(resource, id) {
    return request({
      url: `/groups/edit/${id}`,
      method: "put",
      data: resource,
    });
  }

  delete(id) {
    return request({
      url: `/groups/delete/${id}`,
      method: "delete",
    });
  }

  get_group_students(id, query) {
    return request({
      url: `groups/group/students/${id}`,
      method: "post",
      params: query,
    });
  }
  score(data) {
    return request({
      url: `answers/countSorted`,
      method: "post",
      data: data,
    });
  }
  questions() {
    return request({
      url: `allquestions`,
      method: "GET",
      // data: data
    });
  }
  usersCount() {
    return request({
      url: `usersCount`,
      method: "GET",
      // data: data
    });
  }
  studentscore(id) {
    return request({
      url: `answers/score/${id}`,
      method: "GET",
    });
  }
  confirmScore(data) {
    return request({
      url: `/confirm/score`,
      method: "post",
      data: data,
    });
  }
  sendReport(data) {
    return request({
      url: `/report`,
      method: "post",
      data: data,
    });
  }
  getAnswers(data) {
    return request({
      url: `/getAnswers`,
      method: "post",
      data: data,
    });
  }

  totalAnswers() {
    return request({
      url: `answers/totalAnswers`,
      method: "post",
    });
  }
  get_group_info(id) {
    return request({
      url: `groups/show/${id}`,
      method: "GET",
    });
  }

  getStudentScore(data) {
    return request({
      url: `/answers/studentScore`,
      method: "post",
      data: data,
    });
  }
  //// Get Neomi Exams Data
  getNeomiStudentsData() {
    return request({
      url: `/answers/get/exam/users`,
      method: "get",
    });
  }
  getNeomiGroupsData() {
    return request({
      url: `/answers/get/mobile/exam/groups/user`,
      method: "get",
    });
  }
  getNeomiExamData(payload) {
    return request({
      url: `/answers/get/user/answers`,
      method: "post",
      data: payload,
    });
  }
  getExamScore(payload) {
    return request({
      url: `/answers/get/user/exam/score`,
      method: "post",
      data: payload,
    });
  }
  updateExamData(payload) {
    return request({
      url: `/answers/update/answer`,
      method: "put",
      data: payload,
    });
  }

  // saveExamDetails(payload){
  //   return request({
  //     url: `/answers/save/exam/details`,
  //     method: "post",
  //     data: payload
  //   });
  // }
  getExamDetails(payload) {
    return request({
      url: `/answers/get/exam/details`,
      method: "post",
      data: payload,
    });
  }
  redo_nemoi_test(payload) {
    return request({
      url: `/answers/redo/test`,
      method: "post",
      data: payload,
    });
  }
  reset_nemoi_timer(payload) {
    return request({
      url: `/answers/reset/timer`,
      method: "post",
      data: payload,
    });
  }
  // answers/reset/timer
  print_exam_report(payload) {
    return request({
      url: `/exam/report`,
      method: "post",
      data: payload,
    });
  }
  // for mark -- alkhbeer account
  print_exam_report_mark(payload) {
    return request({
      url: `/exam/report/for/marking`,
      method: "post",
      data: payload,
    });
  }
  // Mark exam report
  submit_exam_report_mark(payload) {
    return request({
      url: `/mark/exam/report`,
      method: "post",
      data: payload,
    });
  }
  // Submit exam to Ai
  submit_exam_to_ai(payload) {
    return request({
      url: `/answers/submit/to/ai`,
      method: "post",
      data: payload,
    });
  }
  get_three_d_answers(answer_id) {
    return request({
      url: `answers/get/json`,
      method: "post",
      data: answer_id,
    });
  }
  get_three_d_evaluations(payload) {
    return request({
      url: `evaluations/get/json`,
      method: "post",
      data: payload,
      withCredentials: false
    });
  }
  get_three_d_advanced(payload) {
    return request({
      url: `advanced/get/json`,
      method: "post",
      data: payload,
      withCredentials: false
    });
  }

  // new neomi exams
  getUsersForNeomiMark() {
    return request({
      url: `/users/to/mark`,
      method: "post",
    });
  }
  getUsersNeomiMarked() {
    return request({
      url: `/users/with/marked/answers`,
      method: "post",
    });
  }

  // New neomi exam
  getNewNeomiExamData() {
    return request({
      url: `/answers/get/neomi/exam/groups/user`,
      method: "get",
    });
  }
  // new exams reports
   getNeomiExamOneReport(payload) {
    return request({
      url: `/answers/test/one/report`,
      method: "post",
      data: payload,
    });
  }
  getNeomiExamTwoReport(payload) {
    return request({
      url: `/answers/test/two/report`,
      method: "post",
      data: payload,
    });
  }
  // getNeomiExamThreeReport(payload) {
  //   return request({
  //     url: `/answers/test/three/report`,
  //     method: "post",
  //     data: payload,
  //   });
  // }
  getPrintNeomiExamForGroup(payload) {
    return request({
      url: `/users/print/group/users/answers`,
      method: "post",
      data: payload,
    });
  }
  // Print all exams for a user
  getPrintNeomiExamForGroupAll(payload) {
    return request({
      url: `/users/print/group/users/answers/all`,
      method: "post",
      data: payload,
    });
  }

  delete_exam_answer_when_correct(payload) {
    return request({
      url: `/answers/delete`,
      method: "post",
      data: payload,
    });
  }
  save_activity_evaluation(payload) {
    return request({
      url: `/evaluation/activity`,
      method: "post",
      data: payload,
    });
  }

  
}
export { Group as default };
