<template>
  <component
    :is="baseComponent"
    :to="link.path ? link.path : '/'"
    class="nav-item"
    :class="{ active: isActive }"
    tag="li"
  >
    <!-- This link toggles a collapsible sub-menu if there are children -->
    <a
      v-if="isMenu"
      class="nav-link sidebar-menu-item d-flex justify-content-start"
      :aria-expanded="!collapsed"
      data-toggle="collapse"
      @click="collapseMenu"
    >
      <i :class="link.icon" style="font-size: 17px"></i>
      <p>
        {{ link.name }}
        <b class="caret"></b>
      </p>
    </a>

    <!-- Sub-menu container (using ElementUI's collapse transition) -->
    <div v-if="$slots.default || isMenu">
      <el-collapse-transition>
        <ul class="nav" v-show="!collapsed">
          <!-- Nested slots -->
          <slot></slot>
        </ul>
      </el-collapse-transition>
    </div>

    <!-- If no children, render a direct link -->
    <slot
      name="title"
      v-if="children.length === 0 && !$slots.default && link.path"
    >
      <component
        :to="link.path"
        @click.native="linkClick"
        :is="elementType(link, false)"
        :class="{ active: link.active }"
        class="nav-link d-flex justify-content-start"
        :target="link.target"
        :href="link.path"
      >
        <template v-if="addLink">
          <!-- <span class="sidebar-mini">{{ link.name.substring(0, 1) }}</span> -->
          <span class="sidebar-normal">{{ link.name }}</span>
        </template>
        <template v-else>
          <i :class="link.icon" style="font-size: 17px" class="ml-2"></i>
          <div class="d-flex justify-content-between w-100">
            <p>{{ link.name }}</p>
            <p v-if="requestsNum >= 0" class="badge badge-warning px-2 py-0">
              {{ requestsNum }}
            </p>
          </div>
        </template>
      </component>
    </slot>
  </component>
</template>

<script>
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";

export default {
  name: "SidebarItem",
  components: {
    [CollapseTransition.name]: CollapseTransition,
  },
  props: {
    link: {
      type: Object,
      default: () => ({
        name: "",
        path: "",
        icon: "",
        children: [],
      }),
    },
    menu: {
      type: Boolean,
      default: false,
    },
    requestsNum: {
      type: Number,
      default: -1,
    },
  },
  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild,
    };
  },
  inject: {
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: { default: true },
  },
  data() {
    return {
      children: [],
      collapsed: true,
    };
  },
  computed: {
    // Decide which base component to render for the outer <component> tag
    // If isRoute === false, or if the item is a "menu" container, we treat it differently
    baseComponent() {
      return this.isMenu || this.link.isRoute ? "li" : "router-link";
    },
    // If we have children or explicitly pass menu=true, it's a collapsible menu
    isMenu() {
      return this.children.length > 0 || this.menu === true;
    },
    // Mark parent <li> as active if any of its children match current route
    isActive() {
      if (this.$route && this.$route.path && this.children.length) {
        let matchingRoute = this.children.find((child) =>
          this.$route.path.startsWith(child.link.path)
        );
        if (matchingRoute) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    addChild(childComponent) {
      const index = this.$slots.default.indexOf(childComponent.$vnode);
      this.children.splice(index, 0, childComponent);
    },
    removeChild(childComponent) {
      const index = this.children.indexOf(childComponent);
      if (index !== -1) {
        this.children.splice(index, 1);
      }
    },
    elementType(link, isParent = true) {
      // If isRoute is false, we use <a>, otherwise <router-link>
      if (link.isRoute === false) {
        return isParent ? "li" : "a";
      } else {
        return "router-link";
      }
    },
    linkClick() {
      // If autoClose is set and the parent sidebar is open, close it
      if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    collapseMenu() {
      this.collapsed = !this.collapsed;
    },
  },
  mounted() {
    // If we are inside another sidebar-item, we add ourselves to its "children" array
    if (this.addLink) {
      this.addLink(this);
    }
    // Initialize collapsed state from link data, if provided
    if (typeof this.link.collapsed !== "undefined") {
      this.collapsed = this.link.collapsed;
    }
    // If the current item is active, expand it (useful if it’s a parent with children)
    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }
  },
  beforeDestroy() {
    // Clean up
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
};
</script>

<style scoped>
.sidebar-menu-item {
  cursor: pointer;
}
</style>
